@import './variables.scss';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Alegreya', serif;
  color: #5A1F69;
  background-image: none;

  & > * {
    box-sizing: border-box;
  }

  p, h1, h2, h3 {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }

  a {
    text-decoration: none;
    color: #5A1F69;

    &:hover {
      text-decoration: underline;
      color: #A5ABBC;
      cursor: pointer;
    }
  }

  .is-underlined {
    text-decoration: underline;
  }

  button {
    border: 2px solid $base-purple;
    border-radius: 5px;
    color: white;
    background-color: $base-purple;
    padding: $base-spacing-half;
    width: 98%;

    &:hover {
      color: $base-purple;
      background-color: white;
    }
  }

  input, textarea, select {
    width: 90%;
    border: none;
    font-family: "Open Sans",sans-serif;
    color: #777;
    border-bottom: 1px solid $base-purple;
    background-color: #f5f5f5;
    padding: $base-spacing;
    margin-bottom: $base-spacing-double;
    cursor: pointer;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    &::-ms-expand {
      display: none;
    }
    -moz-padding-start: calc(10px - 3px);
    outline: none;
    &:focus::-ms-value {
      background-color: transparent;
    }

    &:focus {
      outline: none;
      border: 1px solid $base-grey;
      border-radius: 3px;
    }

  }

  select {
    width: 98%;
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: $header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #5A1F69;
    background-color: white;
    z-index: 100;
    flex-wrap: nowrap;

    @media (min-width: $bp-tablet) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .header__icon {
      width: 40px;
      height: 40px;
      margin-left: $base-spacing;
    }

    .header__title {
      font-size: 24px;
      @media (min-width: $bp-tablet) {
        margin-left: 12px;
      }
    }

    .nav {
      display: block;
      @media (min-width: $bp-tablet) {
        width: 100%;
      }
      @media (min-width: 1600px) {
        display: flex;
        justify-content: center;
      }

      &__button {
        height: 36px;
        width: 36px;
        margin-right: $base-spacing;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        padding: 2px 7px;
        // border: 1px solid #5A1F69;
        // border-radius: 10%;
        background-color: white;
        border: none;
        color: #5A1F69;

        &-line {
          display: block;
          width: 100%;
          height: 0px;
          border-bottom: 2px solid #5A1F69;
          //hack für safari, spans müssen content haben, sonst werden sie ausgeblendet
          color: white;
          overflow: hidden;
        }

        &-close {
          display: none;
        }

        @media (min-width: $bp-tablet) {
          display: none;
        }
      }
      &__links {
        display: none;

        @media (min-width: $bp-tablet) {
          display: flex;
          justify-content: space-evenly;
          padding: 0 12px;
          @media (min-width: 1600px) {
            width: 1600px;
          }
        }
      }

      &--is-expanded {
        
        .nav__button {
          justify-content: center;
          align-items: center;

          &-line {
            display: none;
          }

          &-close {
            display: block;
            font-size: 30px;
            z-index: 2;
          }
        }

        .nav__links {
          position: fixed;
          top: 60px;
          left: 0;
          width: 100vw;
          height: calc(100vh - 60px - 30px);
          background: rgba(255, 255, 255, 0.9);
          // overflow-y: hidden;
          border-top: 1px solid #5A1F69;

          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          font-size: 24px;
          a:hover {
            text-decoration: underline;
          }
        }

        @media (min-width: $bp-tablet) {
          display: none;
        }
      }
    }
  }

  .fixed-bg {
    position: fixed;
    z-index: -100;
    bottom: 51px;
    right: 0;
    height: 65%;

    img {
      height: 100%;
    }
  }

  .content {
    padding-top: $total-header-height + $total-title-height;
    padding-bottom: $total-footer-height;
    overflow: auto;
    // background: url("../images/woman.png") no-repeat 100% 80% fixed;
    // background-size: 28vh;
    min-height: $content-height;
    display: flex;
    justify-content: center;

    & > * {
      max-width: $bp-desktop;
    }

    .title {
      position: fixed;
      top: $total-header-height;
      left: 0;
      background-color: white;
      text-align: center;
      font-size: 18px;
      border-bottom: 1px solid #5A1F69;
      padding: 3px;
      z-index: 90;
      width: 100vw;
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: $footer-height;
    width: 100%;

    border-top: 1px solid #5A1F69;
    background-color: white;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    a, .footer-copyright {
      font-size: 14px;
      margin: 0 6px;
    }

    .footer-copyright {
      display: none;

      @media (min-width: 425px) {
        display: block;
      }
    }
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.info-block {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: $base-spacing-double;
  margin-top: $base-spacing;
  background: rgba(255, 255, 255, 0.8);

  &__header {
    font-size: 24px;
    padding-right: $base-spacing;
    padding-left: $base-spacing;
    border-bottom: 10px solid #5A1F69;
    margin-bottom: $base-spacing;
    min-width: 50%;
  }
  &__text {
    padding-right: $base-spacing;
    text-align: right;

    &.warning {
      color: red;
      text-decoration: underline;
    }

    &-subheader {
      font-size: 19px;
    }
  }
  &__link {
    background-color: #5A1F69;
    font-size: 18px;
    color: white;
    text-align: right;
    margin: 5px 0;
    width: 185px;
    padding: 5px 15px 5px 0;
    font-family: "Open Sans",sans-serif;

    &:hover {
      color: white
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    padding-right: $base-spacing;
    text-align: right;
    justify-content: center;
    align-items: stretch;
  }
}

.image-tiles {
  width: 100%;
  display:flex;
  // flex-direction: column;
  flex-wrap: wrap;
  // padding: 0 4px;

  &-column {
    flex: 50%;
    max-width: 50%;
    // padding: 0 4px;

    @media (min-width: 425px) {
      flex: 25%;
      max-width: 25%;
    }
    @media (min-width: $bp-tablet) {
      flex: 50%;
      max-width: 50%;
    }
    @media (min-width: 1024px) {
      flex: 25%;
      max-width: 25%;
    }

    img {
      margin-top: 8px;
      vertical-align: middle;
      width: calc(100% - 8px);
      padding: 0 4px;
    }
  }
}