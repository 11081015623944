@import './variables.scss';

.atelier {
  display: flex;
  flex-wrap: wrap;

  &__intro, .slider {
    display: flex;
    width: 100%;
  }

  @media (min-width: $bp-tablet) {
    .slider {
      width: 60%
    }
    .atelier__intro {
      width: 40%;
      .info-block {
        margin-top: 0;
        height: 400px;
      }
    }
  }
}