@import './variables.scss';

.naehkurse {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-end;

  .slider, .home__kurse-table {
    width: 100%;

    @media (min-width: $bp-tablet) {
      width: 60%;
    }
  }

  .home__text {
    width: 100%;

    @media (min-width: $bp-tablet) {
      width: 40%;
    }
  }

  .home__kurse-table {
    font-size: 15px;
    @media (min-width: $bp-bigSmartphone) {
      font-size: 18px;

      table, .info-block__text {
        padding-right: $base-spacing-double;

        .subHeaderTable>td {
          padding-top: 15px;
        }
      }
    }
  }

  .home__block-reverse {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: $bp-tablet) {
      flex-direction: row-reverse;
    }
  }

  .info-block  {

    &.is-workshop {
      width: 100%;

      .info-block__header {
        min-width: 32%;
      }

      p {
        text-align: center;
      }

      .card__subText {
        align-self: auto;
      }

      .card {
        @media (min-width: $bp-smallTablet) {
          flex: 1 1 48%;
        }
      }
      .card:first-child {
        padding-top: $base-spacing;
      }
    }
  }
}